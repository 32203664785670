.loaderDotOne {
  left: 6px;
  animation: dot1 0.6s infinite;
}

.loaderDotTwo {
  left: 6px;
  animation: dot2 0.6s infinite;
}

.loaderDotThree {
  left: 20px;
  animation: dot2 0.6s infinite;
}

.loaderDotFour {
  left: 34px;
  animation: dot4 0.6s infinite;
}

@keyframes dot1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dot2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(14px, 0);
  }
}

@keyframes dot4 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
