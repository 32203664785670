.humboEditor :global(div.w-md-editor) {
  border-radius: 8px;

  --color-border-default: rgb(23 10 37 / 0.12);
  --color-canvas-default: transparent;
  --color-fg-default: var(--text-color);
  --color-prettylights-syntax-constant: var(--purple-text);
  --color-accent-fg: var(--purple-text);

  --md-editor-font-family: var(--font-nunito), 'Nunito', PingFang SC, Arial, Helvetica Neue, Microsoft YaHei, sans-serif;
}

.humboEditor[data-color-mode='dark'] :global(div.w-md-editor) {
  --color-border-default: rgb(255 255 255 / 0.1);
}

.humboEditor:focus-within :global(div.w-md-editor) {
  --color-border-default: rgb(101 39 131 / 0.6);
}

.humboEditor[data-color-mode='dark']:focus-within :global(div.w-md-editor) {
  --color-border-default: rgb(212 144 245 / 0.6);
}

.humboEditor :global(div.wmde-markdown) {
  font-size: 20px;
  line-height: 160%;
  font-family: var(--font-nunito), 'Nunito', PingFang SC, Arial, Helvetica Neue, Microsoft YaHei, sans-serif;

  --color-canvas-default: transparent;
  --color-fg-default: var(--text-color);
  --color-prettylights-syntax-constant: var(--purple-text);
  --color-accent-fg: var(--purple-text);
}

.humboEditor :global(::selection) {
  background-color: rgba(101, 39, 131, 0.1) !important;
}

.humboEditor[data-color-mode='dark'] :global(::selection) {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.humboEditor :global(.w-md-editor-text) {
  padding-right: 56px;
}

.humboEditor :global(.w-md-editor-text-pre) > code,
.humboEditor :global(.w-md-editor-text-input) {
  font-size: 18px !important;
  line-height: 24px !important;
}

.humboEditor :global(.w-md-editor-toolbar) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
