.markdownContent {
  font-size: 1.25rem;
  line-height: 160%;
  color: var(--text-color);
}

.markdownContent ul {
  list-style: circle;
}

.markdownContent ol {
  list-style: decimal;
}

.markdownContent p,
.markdownContent ul,
.markdownContent ol {
  margin-bottom: 30px;
}

.markdownContent h2,
.markdownContent h3 {
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 140%;
}

.markdownContent h3 {
  font-size: 1.5rem;
}

.markdownContent h4 {
  margin-top: 6px;
  margin-bottom: 12px;
  line-height: 140%;
  font-weight: 700;
}

.markdownContent a {
  color: var(--purple-text);
  font-weight: 700;
  text-decoration: none;
}

.markdownContent blockquote {
  position: relative;
  font-style: italic;
  font-weight: 600;
  font-size: 26px;
  line-height: 135%;
}

.markdownContent blockquote::before {
  z-index: -1;
  content: '';
  width: 4px;
  height: 100%;
  border-radius: 2px;
  background-color: var(--purple);
  position: absolute;
  left: -24px;
}

@media (max-width: 1000px) {
  .markdownContent ol,
  .markdownContent ul {
    margin-left: 1em;
  }
}

@media (max-width: 800px) {
  .markdownContent blockquote {
    margin-left: 1em;
  }
}

@media (max-width: 500px) {
  .markdownContent {
    font-size: 1.125rem;
    line-height: 150%;
  }

  .markdownContent p,
  .markdownContent ul,
  .markdownContent ol {
    margin-bottom: 24px;
  }
}
